/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-24",
    versionChannel: "nightly",
    buildDate: "2024-09-24T00:06:22.266Z",
    commitHash: "c766fe6cd471912135227eea898f545901fb6ed2",
};
